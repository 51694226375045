'use strict';



class ModalForgottenMdp extends React.Component {
  constructor(props) {
    super(props);
  }
  
   
  render() {
    return (
        <React.Fragment>
          <div className="modal fade" id="modalForgottenMdp" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                    <h4 id="titleModalForgottenMdp" className="modal-title">Mot de passe oublié </h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <input type="email" className="form-control" id="emailRecovery" placeholder="Entrer votre courriel" />
                        <br/>
                        <button type="button"   id="btnSendEmailRecovery"  className="btn btn-primary">Envoyer courriel de récupération</button>
                        
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
      );
       


  }
  
}

const domContainerModalForgottenMdp= document.querySelector('#modalModalForgottenMdp');
ReactDOM.render(<ModalForgottenMdp></ModalForgottenMdp>, domContainerModalForgottenMdp);


$(document).ready(function(){
    $("#btnSendEmailRecovery").click(function() {
        sendEmailRecovery();
    });
   

  })

function traductionModalForgottenMdp(language){
    switch(language){
      case "FR":
        $("#titleModalForgottenMdp").text("Mot de passe oublié");
        $("#btnSendEmailRecovery").text("Envoyer courriel de récupération");
        $("#emailRecovery").attr("placeholder","Entrer votre courriel");
          break;

      case "EN":
        $("#titleModalForgottenMdp").text("Forgot your password");
        $("#btnSendEmailRecovery").text("Send recovery email");
        $("#emailRecovery").attr("placeholder","Enter your email");
          break;
    }
}

function sendEmailRecovery(){
    const email =   $('#emailRecovery').val(); 
    if(!email || email === ""){
        return;
    }

    let isModalWaitingStarted = prepareModalWaiting();
    $.ajax( {
        url: '/api/utilisateur/sendEmailForgottenAccount',
        type: 'POST',
        data: {
            email:email
        },
        success: function(data){
          hideModalWaiting(isModalWaitingStarted);
          alert(traductionPhrase("Le courriel de récupération a été envoyé","The recovery email has been sent"));
          window.location.reload();
        },
        error: function (error) {
          hideModalWaiting(isModalWaitingStarted);
          alert(traductionPhrase("Cet adresse courriel est associé à aucun compte patient.","This email address is not associated with any patient account."));
          //alert(traductionPhrase("Le courriel de récupération a été envoyé","The recovery email has been sent"));
        }
    } );
    showModalWaiting(isModalWaitingStarted);
}











